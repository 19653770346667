import client from 'api/client';
import { AxiosResponse } from 'axios';
import {
  DriverPeriodResource,
  DriversWithDealsResource,
  DriversWithOvertimeDealResource,
} from 'src/pages/deals/types/drivers';
import {
  IDealFilters,
  OvertimeDealInput,
  SignOvertimeDealInput,
} from 'src/types/filters';
import { IFilters } from 'src/types/filters';
import { formatDateApi } from 'src/utils/datetimeHelper';
import { DriverResource, DriverListResource } from 'types/drivers';

export async function getEmployees(
  isActive: boolean
): Promise<AxiosResponse<DriverResource[]>> {
  return await client.get<DriverResource[]>(
    `/api/Drivers/GetAllDrivers?includeSettled=${isActive ? 'false' : 'true'}`
  );
}

export async function getDriversWithDeals(
  filters: IDealFilters
): Promise<AxiosResponse<DriversWithDealsResource>> {
  return await client.post<DriversWithDealsResource>(
    '/api/Drivers/GetDriversWithDeals',
    {
      ruts: filters.ruts.length > 0 ? filters.ruts : null,
      rol: 0,
      isActive: filters.isActive,
    }
  );
}

export async function getDriverWithAllDeals(
  id: string
): Promise<AxiosResponse<DriversWithOvertimeDealResource>> {
  return await client.get<DriversWithOvertimeDealResource>(
    '/api/Drivers/GetDriverWithAllDeals',
    {
      params: {
        id: id,
      },
    }
  );
}

export async function createOvertimeDeals(
  overtimeDealInput: OvertimeDealInput
): Promise<AxiosResponse> {
  return await client.post(
    '/api/Drivers/CreateOvertimeDeals',
    overtimeDealInput
  );
}

export const getDealPeriods = (): Promise<
  AxiosResponse<DriverPeriodResource[]>
> => {
  return client.get<DriverPeriodResource[]>('/api/Drivers/GetDealPeriods');
};
export async function getDriversWithUnsignedDeal(): Promise<void> {
  await client.get('/Drivers/ReminderDriversWithUnsignedDeals');
  return;
}

export async function getDriversByShift(
  props: IFilters
): Promise<AxiosResponse<DriverListResource[]>> {
  return await client.post<DriverListResource[]>(
    '/api/Drivers/GetDriversByShift',
    {
      startDate: formatDateApi(props.startDate),
      endDate: formatDateApi(props.endDate),
      shiftNumbers: props.shifts.map((a) => a.id),
    }
  );
}

export async function signDriverOvertimeDeal(
  props: SignOvertimeDealInput
): Promise<AxiosResponse> {
  return await client.patch('/api/Drivers/SignDriverOvertimeDeal', props);
}
