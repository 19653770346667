import { ColumnsConfig } from 'src/components/document/components/pdf/attendancePDF';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const descendingComparatorDate = (a: Date, b: Date) => {
  if (b.getTime() < a.getTime()) {
    return -1;
  }
  if (b.getTime() > a.getTime()) {
    return 1;
  }
  return 0;
};

export function sumValues(items: ColumnsConfig[]) {
  return items.reduce((a, b) => a + b.width, 0);
}
