import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import MetbusSplashScreen from 'components/MetbusSplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';
import { SnackbarUtilsConfigurator } from 'src/utils/snackbarUtilsConfigurator';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const theme = createTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    // typography: settings.typography,
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        dense
        autoHideDuration={3000}
        preventDuplicate
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <SnackbarUtilsConfigurator />
        {/* <GlobalStyles2 /> */}
        {auth.isInitialized ? content : <MetbusSplashScreen />}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
