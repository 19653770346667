import { create } from 'src/store/createStore';
import { persist, createJSONStorage } from 'zustand/middleware';
import { DateRange } from 'components/custom/datePicker/useDefaultRanges';
import { DriverResource } from 'src/types/drivers';
import { CostCentre } from 'src/types/costCentre';
import { endOfDay, startOfDay } from 'date-fns';

interface FilterDataState {
  isOpen: boolean;
  selectedRange: DateRange;
  selectedTerminus: CostCentre[];
  selectedEmployees: DriverResource[];
  isActive: boolean;
  setSelectedEmployees: (value: DriverResource[]) => void;
  setSelectedTerminus: (value: CostCentre[]) => void;
  setSelectedRange: (startDate: Date, endDate: Date) => void;
  setIsActive: (value: boolean) => void;
  switchOpen: () => void;
  reset: () => void;
}

export const filterDataStore = create<FilterDataState>()(
  persist(
    (set) => ({
      isOpen: true,
      selectedRange: {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
      },
      selectedEmployees: [],
      selectedTerminus: [],
      isActive: true,
      setSelectedEmployees: (value: DriverResource[]) =>
        set(() => ({ selectedEmployees: value })),
      setSelectedTerminus: (value: CostCentre[]) =>
        set(() => ({ selectedTerminus: value })),
      setSelectedRange: (startDate: Date, endDate: Date) => {
        const dateRange: DateRange = {
          startDate,
          endDate,
        };
        set({ selectedRange: dateRange });
      },
      setIsActive: (value: boolean) => set({ isActive: value }),
      switchOpen: () => set((state) => ({ isOpen: !state.isOpen })),
      reset: () => {
        set({
          isOpen: true,
          selectedRange: {
            startDate: startOfDay(new Date()),
            endDate: endOfDay(new Date()),
          },
          selectedEmployees: [],
          selectedTerminus: [],
          isActive: true,
        });
      },
    }),
    {
      name: 'filter-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
