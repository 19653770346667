import { IpRange } from 'src/types/accessByIP';
import { UserProfiles } from './userProfiles';

export interface LoginResponse {
  authorization: string;
  user: IUser;
  succeeded: boolean;
  message: string;
}

export interface AccessByIPRange extends Omit<LoginResponse, 'user'> {
  ipRanges: IpRange[];
  user: IUser | null;
}
export interface IUser {
  userName: string;
  name: string;
  email: string;
  profile: UserProfiles;
  rut: string;
  rol: number;
  lastLogin: Date;
}

export const DefaultIUser = {
  userName: '',
  name: '',
  email: '',
  profile: UserProfiles.Conductor,
  rut: '',
  rol: 0,
  lastLogin: new Date(),
};

export interface IdentityClaims {
  userName: string;
  name: string;
  picture: string;
  position: string;
  email: string;
  rut: string;
  rol: string;
}

export interface AuthorizationClaims {
  userName: string;
  costCentres: string;
  profile: string;
  profileName: string;
  exp: number;
}

export interface Token {
  token: string;
  expires: Date;
}

export interface IdentityResponse {
  identity: IdentityClaims;
  expired: Date;
}
