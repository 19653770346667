import { create } from 'src/store/createStore';
import {
  getEmployees,
  getDriversByShift,
  getDealPeriods,
} from 'src/api/drivers';
import { getTerminus } from 'src/api/costCentre';
import { DriverResource, DriverListResource } from 'src/types/drivers';
import { CostCentre } from 'src/types/costCentre';
import { descendingComparator } from 'src/utils/comparerHelper';
import { persist, createJSONStorage } from 'zustand/middleware';
import { DriverPeriodResource } from 'src/pages/deals/types/drivers';
import { IFilters } from 'src/types/filters';

interface CommonDataState {
  employees: DriverResource[];
  terminus: CostCentre[];
  dealPeriods: DriverPeriodResource[];
  driverShifts: DriverListResource[];
  updateBaseEmployees: (isActive: boolean) => void;
  updateBaseDealPreriod: () => void;
  setBaseData: () => void;
  reset: () => void;
  setShiftData: (filter: IFilters) => void;
  isLoadingShift: boolean;
}

export const commonDataStore = create<CommonDataState>()(
  persist(
    (set) => ({
      isLoadingShift: false,
      employees: [],
      terminus: [],
      dealPeriods: [],
      driverShifts: [],
      updateBaseEmployees: (isActive: boolean) => {
        getEmployees(isActive)
          .then((response) => {
            if (response.status && response.data.length > 0) {
              set({ employees: response.data });
            } else {
              set({ employees: [] });
            }
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
      updateBaseDealPreriod: () => {
        getDealPeriods()
          .then((response) => {
            if (response.status && response.data.length > 0) {
              set({ dealPeriods: response.data });
            } else {
              set({ dealPeriods: [] });
            }
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      },
      setBaseData: () => {
        Promise.allSettled([getEmployees(false), getTerminus()])
          .then((values) => {
            const [employeeResponse, terminusResponse] = values;
            let employees: DriverResource[] = [];
            if (employeeResponse.status === 'fulfilled') {
              employees =
                employeeResponse.value.status &&
                employeeResponse.value.data.length > 0
                  ? employeeResponse.value.data
                  : [];
            }
            let terminus: CostCentre[] = [];
            if (terminusResponse.status === 'fulfilled') {
              terminus =
                terminusResponse.value.status &&
                terminusResponse.value.data.length > 0
                  ? terminusResponse.value.data.sort(
                      (a, b) => -descendingComparator(a, b, 'costCentreId')
                    )
                  : [];
            }

            set({
              employees: employees,
              terminus: terminus,
            });
          })
          // eslint-disable-next-line no-console
          .catch((error) => console.error(error));
      },
      setShiftData: (filter: IFilters) => {
        set({ isLoadingShift: true });
        getDriversByShift(filter)
          .then((response) => {
            if (response.status && response.data.length > 0) {
              set({ driverShifts: response.data });
            }
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err))
          .finally(() => set({ isLoadingShift: false }));
      },
      reset: () => {
        set({
          employees: [],
          terminus: [],
          driverShifts: [],
        });
      },
    }),
    {
      name: 'common-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
