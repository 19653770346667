import { AxiosError, AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { refreshToken, requestAuthorization } from 'api/authenticate';
import client from 'api/client';
import { httpErrorNotification } from 'api/httpErrorNotification';
import setAuthorizationToken from 'api/setAuthorizationToken';
import useAuth from 'hooks/useAuth';
import { UserProfiles } from 'src/types/userProfiles';

interface RetryConfig extends AxiosRequestConfig {
  retry?: boolean;
}
export const AxiosConfigurator: React.FC = () => {
  const { user, logout } = useAuth();

  let e401resolved = true;
  useEffect(() => {
    const responseIntercept = client.interceptors.response.use(
      (response) => response,
      async (err: AxiosError) => {
        if (err.response?.status === 401 && e401resolved) {
          const config: RetryConfig = err.config ?? { retry: false };
          if (client.defaults.headers.common.Authorization && !config.retry) {
            e401resolved = false;
            config.retry = true;
            const response = await refreshToken();
            if (response.status === 200) {
              if (config.headers) {
                const version = localStorage.getItem('version') ?? '';
                const authRes = await requestAuthorization(version);
                const authorizationToken = authRes.data.token;
                localStorage.setItem('authorizationToken', authorizationToken);
                setAuthorizationToken(authorizationToken);
                config.headers.Authorization = `Bearer ${authorizationToken}`;
                e401resolved = true;
                return client(config);
              }
            }
          }
        }

        if (err.response?.status === 401) {
          if (user?.profile === UserProfiles.Inspector) {
            window.location.replace('/');
            return Promise.reject();
          } else {
            logout();
            return Promise.reject();
          }
        }
        httpErrorNotification(err);
      }
    );

    return () => {
      client.interceptors.response.eject(responseIntercept);
    };
  }, []);
  return null;
};
