import client from 'api/client';
import { AxiosResponse } from 'axios';
import { CostCentre } from 'src/types/costCentre';

export async function getTerminus(): Promise<AxiosResponse<CostCentre[]>> {
  return await client.get<CostCentre[]>('/api/CostCentres');
}

export const getTerminusById = (
  costCentreId: number
): Promise<AxiosResponse<CostCentre>> => {
  return client.get<CostCentre>(`/api/CostCentres/${costCentreId}`);
};
