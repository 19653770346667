import { AxiosError } from 'axios';
import { createContext, useEffect, useReducer, ReactNode } from 'react';
import getAppVersion from 'api/appVersion';
import { Login, Logout, getAutorizationResponse } from 'api/authenticate';
import setAuthorizationToken from 'api/setAuthorizationToken';
import { commonDataStore, filterDataStore, resetAllStores } from 'src/store';
import { IpRange } from 'types/accessByIP';
import {
  type LoginResponse,
  type IUser,
  DefaultIUser,
  AccessByIPRange,
  IdentityClaims,
} from 'types/loginResponse';
import { LoginType } from 'src/types/loginData';
import { UserProfiles } from 'src/types/userProfiles';

interface IPayloadState {
  isAuthenticated: boolean;
  user: IUser;
  version: string;
  ipRanges: IpRange[];
  loginType: LoginType;
}

interface State extends Omit<IPayloadState, 'user'> {
  isInitialized: boolean;
  user: IUser | null;
}
interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  setLoginType: (loginType: LoginType) => void;
  loginByIp: (accessByIPRange: AccessByIPRange) => void;
}
interface AuthProviderProps {
  children?: ReactNode;
}

interface InitializeAction {
  type: 'INITIALIZE';
  payload: IPayloadState;
}

interface LoginAction {
  type: 'LOGIN';
  payload: {
    user: IUser;
  };
}

interface LoginByIpAction {
  type: 'LOGIN_BY_IP';
  payload: IPayloadState;
}

interface LogoutAction {
  type: 'LOGOUT';
}
interface TypeLoginAction {
  type: 'SET_LOGIN_TYPE';
  payload: { loginType: LoginType };
}

type Action =
  | InitializeAction
  | LoginAction
  | LoginByIpAction
  | LogoutAction
  | TypeLoginAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  version: '',
  ipRanges: [],
  loginType: LoginType.NORMAL,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: Action): State => {
    const { isAuthenticated, user, version, ipRanges } = (
      action as InitializeAction
    ).payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      version,
      ipRanges,
    };
  },
  LOGIN: (state: State, action: Action): State => {
    const { user } = (action as LoginAction).payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGIN_BY_IP: (state: State, action: Action): State => {
    const { user, version, ipRanges } = (action as LoginByIpAction).payload;
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
      version,
      ipRanges,
      loginType: LoginType.INSPECTOR,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    loginType: LoginType.NORMAL,
  }),
  SET_LOGIN_TYPE: (state: State, action: Action): State => {
    const { loginType } = (action as TypeLoginAction).payload;
    return {
      ...state,
      loginType,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type](state, action);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {
    // lala
  },
  setLoginType: () => {
    // lala
  },
  loginByIp: () => {
    // lala
  },
});

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setBaseData } = commonDataStore((state) => state);
  const resetfilter = filterDataStore((state) => state.reset);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const payload: IPayloadState = {
        isAuthenticated: false,
        user: DefaultIUser,
        version: '',
        ipRanges: [],
        loginType: state.loginType,
      };

      try {
        const authorizationToken =
          localStorage.getItem('authorizationToken') ?? '';
        const identityData = localStorage.getItem('identityData') ?? '';
        if (authorizationToken) setAuthorizationToken(authorizationToken);

        const [VersionRes] = await Promise.allSettled([getAppVersion()]);

        if (VersionRes.status === 'fulfilled') {
          const version = VersionRes.value;
          payload.version = version;
          localStorage.setItem('version', version);
        }

        if (authorizationToken !== '') {
          const response = getAutorizationResponse(
            JSON.parse(identityData) as IdentityClaims,
            authorizationToken
          );

          if (response.succeeded) {
            const { user: inspector, authorization } = response;
            payload.user = inspector;
            payload.isAuthenticated = true;
            localStorage.setItem('authorizationToken', authorization);
            setAuthorizationToken(authorization);
            setBaseData();
            resetfilter();
          }
        }

        dispatch({
          type: 'INITIALIZE',
          payload: payload,
        });
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: payload,
        });
      }
    };

    // eslint-disable-next-line no-console
    initialize().catch((err) => console.error(err));
  }, [state.loginType]);

  const setSession = (loginResponse: LoginResponse | null): void => {
    if (loginResponse?.succeeded) {
      const { authorization } = loginResponse;
      localStorage.setItem('authorizationToken', authorization);
      setAuthorizationToken(authorization);
      if (loginResponse.user.profile !== UserProfiles.Conductor) setBaseData();
    } else {
      localStorage.removeItem('authorizationToken');
      setAuthorizationToken(null);
    }
  };

  const login = async (username: string, password: string): Promise<void> => {
    const response = await Login({ username, password });
    if (typeof response === 'string' || typeof response === 'undefined')
      throw new Error(response);
    const loginResponse: LoginResponse = response;
    setSession(loginResponse);
    if (!loginResponse.succeeded) {
      throw new Error(loginResponse.message);
    }
    dispatch({
      type: 'LOGIN',
      payload: {
        user: loginResponse.user,
      },
    });
  };

  const logout = () => {
    const version = localStorage.getItem('version') ?? '';
    const authorization = localStorage.getItem('authorizationToken') ?? '';
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    resetAllStores();
    Logout({ authorization, version })
      // eslint-disable-next-line no-console
      .then(() => console.log('logged out'))
      .catch((err: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
      });
  };

  const setLoginType = (loginType: LoginType) => {
    dispatch({
      type: 'SET_LOGIN_TYPE',
      payload: {
        loginType,
      },
    });
  };

  const loginByIp = (accessByIPRange: AccessByIPRange): void => {
    if (accessByIPRange.succeeded) {
      const { user: inspector, authorization, ipRanges } = accessByIPRange;

      localStorage.setItem('authorizationToken', authorization);
      setAuthorizationToken(authorization);
      setBaseData();
      resetfilter();

      dispatch({
        type: 'LOGIN_BY_IP',
        payload: {
          ...state,
          user: inspector ?? DefaultIUser,
          ipRanges,
          loginType: state.loginType,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        setLoginType,
        loginByIp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
