import { AxiosError, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import userClient from './userClient';
import client from 'api/client';
import { systemId } from 'src/app.config';
import { IpToken, IpRange } from 'types/accessByIP';
import {
  AuthorizationClaims,
  LoginResponse,
  IUser,
  IdentityClaims,
  IdentityResponse,
  AccessByIPRange,
  Token,
} from 'types/loginResponse';
import { UserProfiles } from 'types/userProfiles';
import { getErrorMessage } from 'utils/errorHelper';

export async function Me(): Promise<IUser | undefined> {
  try {
    const user = await client.get<IUser>('/Login/Me');
    if (user.status) return user.data;
    return undefined;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.data) return err.response.data as IUser;
    return undefined;
  }
}
export interface LoginProps {
  username: string;
  password: string;
}

export async function Login(
  loginProps: LoginProps
): Promise<LoginResponse | string | undefined> {
  try {
    const idRes = await userClient.post<IdentityResponse>(
      '/api/Token/RequestIdentity',
      loginProps,
      {
        withCredentials: true,
      }
    );
    const { identity } = idRes.data;
    localStorage.setItem('IdentityData', JSON.stringify(identity));
    const version = localStorage.getItem('version') ?? '';
    const auRes = await requestAuthorization(version);
    const authorization = auRes.data.token;
    if (authorization == '')
      return 'Ha ocurrido un error en la autentificación.';

    return getAutorizationResponse(identity, authorization);
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.data) return err.response.data as string;
    return 'Ha ocurrido un error en la autentificación.';
  }
}

export const getAutorizationResponse = (
  identity: IdentityClaims,
  authorization: string
) => {
  const { userName, name, email, rut, rol } = identity;
  const { profileName, exp }: AuthorizationClaims = jwtDecode(authorization);
  const user = {
    userName,
    name,
    email,
    profile: profileName as UserProfiles,
    rut,
    rol: Number(rol),
  };
  let succeeded = true;
  let message = '';
  if (!validateUserProfile(user.profile)) {
    succeeded = false;
    message = 'No tiene permiso para ingresar al sistema.';
  }

  if (new Date(exp * 1000) < new Date()) {
    succeeded = false;
  }
  return {
    authorization,
    user,
    succeeded,
    message,
  } as LoginResponse;
};

const validateUserProfile = (profile: UserProfiles) => {
  if (profile === UserProfiles.Conductor) {
    return true;
  }
  return true;
};

export const refreshToken = (): Promise<AxiosResponse<Token>> => {
  return userClient.get<Token>('api/Token/RefreshToken', {
    withCredentials: true,
  });
};

export const requestAuthorization = (
  version: string
): Promise<AxiosResponse<Token>> => {
  return userClient.get<Token>('/api/Token/RequestAuthorization', {
    params: {
      systemId,
      version,
    },
    withCredentials: true,
  });
};

export interface ChangePasswordProps {
  userName: string;
  currentPassword: string;
  newPassword: string;
}

export async function ChangePassword(
  changeProps: ChangePasswordProps
): Promise<{ ok: boolean; message: string }> {
  try {
    const response = await userClient.put<string>(
      '/api/User/ChangePassword',
      changeProps
    );
    switch (response.status) {
      case 200:
        return {
          ok: true,
          message: 'Se ha modificado correctamente la contraseña.',
        };
      case 400:
        return { ok: false, message: 'Usuario incorrecto.' };
      default:
        return { ok: false, message: 'Ha ocurrido un error.' };
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err.status === 400)
      return { ok: false, message: 'Usuario incorrecta.' };
    return {
      ok: false,
      message: 'Ha ocurrido un error al cambiar la contraseña.',
    };
  }
}

export const requestAccessByIP = (): Promise<AccessByIPRange | null> => {
  return userClient
    .get<IpToken>('/api/Token/RequestIdentityByIP', {
      withCredentials: true,
    })
    .then(async (response) => {
      if (response.status === 204) {
        return {
          ipRanges: [],
          identity: '',
          authorization: '',
          user: null,
          succeeded: false,
          message: '',
        };
      }
      const { identity: idRes, ranges } = response.data;
      const { identity } = idRes;
      localStorage.setItem('IdentityData', JSON.stringify(identity));
      const version = localStorage.getItem('version') ?? '';
      const auRes = await requestAuthorization(version);
      const authorization = auRes.data.token;
      const { user, succeeded, message } = getAutorizationResponse(
        identity,
        authorization
      );
      return {
        ipRanges: ranges,
        identity,
        authorization,
        user,
        succeeded,
        message,
      };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(`Error on IP range request: ${getErrorMessage(error)}`);
      throw error;
    });
};

export const updateAccessByIP = (
  updatedAccess: IpRange
): Promise<AxiosResponse<IpRange>> =>
  client.post('/Login/IpAccessUpdate', updatedAccess);

export const resetPassword = (userName: string): Promise<AxiosResponse<void>> =>
  userClient.put(
    '/api/User/ResetPasswordUser',
    {},
    {
      params: {
        userName,
      },
    }
  );

export interface LogoutProps {
  authorization: string;
  version: string;
}

export const Logout = async ({ authorization, version }: LogoutProps) => {
  return userClient.get('/api/ConnectionLog/RegisterLogout', {
    headers: {
      Authorization: `Bearer ${authorization}`,
    },
    params: {
      systemId,
      version,
    },
    withCredentials: true,
  });
};
